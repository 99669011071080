<template lang="pug">
AppLayout
  template(v-slot:title) UI

  t-list(title="General")
    t-list-item(title="Basket", to="/ui/basket")
    t-list-item(title="List", to="/ui/list")

  t-list(title="Kassa")
    t-list-item(title="Payment", to="/ui/payment")
    t-list-item(title="Check", to="/ui/check")
    v-list-item
      v-btn(to="/ui/basket", block, depressed, outlined, color="primary") Basket
    v-list-item
      v-btn(to="/ui/list", block, depressed, outlined, color="primary") List
    v-list-item
      v-btn(to="/ui/payment", block, depressed, outlined, color="primary") Payment
    v-list-item
      v-btn(to="/ui/check", block, depressed, outlined, color="primary") Check
</template>
<script>

import { mapState, mapGetters, mapActions } from 'vuex'
import AppLayout from 'T@/AppLayout.vue'

export default {
  components: {
    AppLayout,
  },
  data: () => ({
  }),
  computed: {
  },
  methods: {
  },

}
</script>
<style lang="stylus"></style>
